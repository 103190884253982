import { Injectable, NgZone } from '@angular/core';
import {
  Auth,
  signOut,
  signInWithPopup,
  signInWithCredential,
  authState,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider
} from '@angular/fire/auth';
import { doc, Firestore, getDoc, setDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { IUser, UserUtils } from '../models/user.model';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const providers = environment.providers;
const PROVIDER_KEY = "provider";
const ACQUARIUS_KEY = "acquarius";
const SUBSCRIPTION_TYPES: any = {
  SIENNASPHEREAPPESSENTIAL: "Sienna Sphere Essential"
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user!: IUser;
  public credential!: any;
  public provider!: string;
  acquariusAccount!: any;
  acusticaSubscription: any;
  isLoggedIn: boolean = false;

  constructor(
    public auth: Auth,
    public router: Router,
    public ngZone: NgZone,
    private http: HttpClient,
    private db: Firestore,
    private oidcSecurityService: OidcSecurityService
  ) {
    authState(this.auth).subscribe(profile => {
      this.onLoginCallback(profile);
    });
    this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
      if (this.user)
        this.onAcquariusLinkCallback(loginResponse);
    });

  }

  onUserLoginObservable() {
    return authState(this.auth);
  }

  async loginGoogle() {
    localStorage.setItem(PROVIDER_KEY, providers.GOOGLE);
    let provider = new GoogleAuthProvider();
    await signInWithPopup(this.auth, provider);
  }

  async loginFacebook() {
    localStorage.setItem(PROVIDER_KEY, providers.FACEBOOK);
    let provider = new FacebookAuthProvider();
    await signInWithPopup(this.auth, provider);
  }

  async loginApple() {
    localStorage.setItem(PROVIDER_KEY, providers.APPLE);
    let provider = new OAuthProvider(providers.APPLE);
    await signInWithPopup(this.auth, provider);
  }

  async loginKeycloak() {
    localStorage.setItem(PROVIDER_KEY, providers.KEYCLOAK);
    let provider = new OAuthProvider(providers.KEYCLOAK);
    await signInWithPopup(this.auth, provider);
  }

  async linkAcquariusAccount() {
    this.oidcSecurityService.authorize();
  }

  async unlinkAcquariusAccount() {
    await this.removeAcquariusAccount();
    await this.oidcSecurityService.logoff();
    this.acquariusAccount = null;
  }

  canLinkAcquariusAccount() {
    const item = localStorage.getItem(PROVIDER_KEY);
    return providers.KEYCLOAK !== item;
  }

  signOut() {
    return signOut(this.auth).then(() => {
      this.oidcSecurityService.logoff();
      localStorage.removeItem(PROVIDER_KEY);
      this.router.navigate(["sign-in"]);
      this.isLoggedIn = false;
    });
  }

  goHome() {
    this.router.navigate(['home']);
  }

  async onLoginCallback(result: any) {
    if (!result) return;
    this.provider = result.providerData[0].providerId;
    let provider = localStorage.getItem(PROVIDER_KEY);
    let user: IUser = result.providerData.filter((item: any) => { return provider ? item.providerId === provider : !!item.providerId; })[0] as IUser;
    user.authProviders = result.providerData.map((item: any) => { return item.providerId; });
    user.uid = result.uid;
    this.user = user
    this.isLoggedIn = true;
    await this.setUser(user)
    this.credential = result;
    this.acquariusAccount = await this.getAcquariusAccount();
    if (this.acquariusAccount && this.acquariusAccount.sub) {
      this.acusticaSubscription = await this.getAcusticaSubscription();
    }
    this.goHome();
  }

  async onAcquariusLinkCallback(result: LoginResponse) {
    if (!result) return;
    const { isAuthenticated, userData, accessToken, idToken, configId } = result;
    this.acquariusAccount = userData;
    await this.setAcquariusAccount(this.acquariusAccount);
    this.acusticaSubscription = await this.getAcusticaSubscription();
  }

  isAcquariusAccountLinked() {
    return !!this.acquariusAccount;
  }

  async setUser(user: IUser) {
    const payload = UserUtils.userToFirebaseModel(user);
    const docSnap = await setDoc(doc(this.db, "users", user.uid as string), payload);
    return docSnap;
  }

  async setAcquariusAccount(account: any) {
    const userId = this.user.uid;
    const payload = { acquariusAccount: account };
    const existingAccuariusAccount = this.getAcquariusAccount();
    if (!!existingAccuariusAccount) {
      return await setDoc(doc(this.db, "usersPrivate", userId as string), payload);
    } else {
      return await setDoc(doc(this.db, "usersPrivate", userId as string), payload, { merge: true, mergeFields: ["acquariusAccount"] });
    }
  }

  async getAcquariusAccount() {
    const docRef = doc(this.db, "usersPrivate", this.user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let account = docSnap.data() as any;
      return account.acquariusAccount ? account.acquariusAccount : null;
    } else return null;
  }

  async removeAcquariusAccount() {
    const userId = this.user.uid;
    const docRef = doc(this.db, "usersPrivate", this.user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let account = docSnap.data();
      account["acquariusAccount"] = null;
      return await setDoc(doc(this.db, "usersPrivate", userId as string), account, { merge: true, mergeFields: ["acquariusAccount"] });
    }
  }

  async getAcusticaSubscription() {
    const endpoint = environment.endpoints['Fabio.Sevice'] + "/acustica/subscription";
    return this.auth.currentUser?.getIdToken()
      .then((token: string) => {
        return this.http.get(endpoint, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        }).toPromise();
      })
  }

  async syncAcusticaOrders() {
    const endpoint = environment.endpoints['Fabio.Sevice'] + "/acustica/orders";
    return this.auth.currentUser?.getIdToken()
      .then((token: string) => {
        return this.http.get(endpoint, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        }).toPromise();
      })
  }

  isSubscriptionValid() {
    return this.acusticaSubscription && this.acusticaSubscription.Active
  }

  getStorageLimit() {
    let maxSize = this.isSubscriptionValid() ? environment.acustica.storageSizeTier1 : environment.acustica.storageSizeFree;
    return maxSize;
  }

  getSubscriptionCaption() {
    const sku = this.acusticaSubscription.Sku;
    return SUBSCRIPTION_TYPES[sku];
  }

  gotToSubscriptionsManagement() {
    const url = this.acusticaSubscription.ManageSubscriptionsUrl;
    window.open(url, "_blank");
  }
  goToShop() {
    const url = environment.acustica.storeUrl;
    window.open(url, "_blank");
  }

}
