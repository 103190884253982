import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class DisplayService {

    public isMobile: boolean = false;
    constructor(
    ) {

    }

    
  checkScreenSize() {
    this.isMobile = document.getElementsByTagName("body")[0].clientWidth < 1000;
  }


}
