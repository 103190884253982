import { Analytics } from "@angular/fire/analytics/firebase";
import { IVersion } from "../models/project.model";
import { v4 as uuidv4 } from "uuid";
const WavEncoder = require('wav-encoder');

const showdown = require("showdown");
const jsPDF = require('jspdf');


export class Utils {
  public static parseQueryString(str: string) {
    const ret = Object.create(null);

    if (typeof str !== 'string') {
      return ret;
    }

    str = str.trim().replace(/^(\?|#|&)/, '');

    if (!str) {
      return ret;
    }

    str.split('&').forEach((param) => {
      const parts = param.replace(/\+/g, ' ').split('=');
      // Firefox (pre 40) decodes `%3D` to `=`
      // https://github.com/sindresorhus/query-string/pull/37
      let key = parts.shift();
      let val = parts.length > 0 ? parts.join('=') : undefined;

      key = decodeURIComponent(key!);

      // missing `=` should be `null`:
      // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
      val = val === undefined ? "" : decodeURIComponent(val);

      if (ret[key] === undefined) {
        ret[key] = val;
      } else if (Array.isArray(ret[key])) {
        ret[key].push(val);
      } else {
        ret[key] = [ret[key], val];
      }
    });

    return ret;
  }

  public static getFileExtension(fileName: string) {
    return fileName.split(".")[fileName.split(".").length - 1];
  }

  public static downloadMarkdownAsPdf(version: IVersion) {
    const converter = new showdown.Converter();
    const text = version.note;
    const fileName = version.number + "-" + version.name + ".pdf";
    const doc = new jsPDF();
    let convertedMarkdown = converter.makeHtml(text);
    doc.fromHTML(convertedMarkdown, 15, 15, {
      width: 190
    });

    doc.save(fileName);
  }

  public static downloadHtmlAsPdf(version: IVersion) {
    const text = version.note;
    const fileName = version.number + "-" + version.name + ".pdf";
    const doc = new jsPDF();
    doc.fromHTML(text, 15, 15, {
      width: 190
    });

    doc.save(fileName);
  }

  public static formatSeconds(seconds: number) {
    return new Date(seconds * 1000).toISOString().slice(11, 23);
  }

  public static newGuid() {
    return uuidv4();
  }

  public static downloadBlob(blob: Blob, fileName: string) {
    let a = document.createElement("a") as any;
    let url = window.URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  public static downloadUri(uri: string, fileName: string) {
    const options = {
      headers: {

      }
    };
    fetch(uri, options)
      .then(res => res.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  public static formatBytes(bytes: number, size: string = "MiB", decimals: number = 2) {
    //Available Sizes 'Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'
    if (!+bytes) return 0

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = [size]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return Number((bytes / Math.pow(k, i)).toFixed(dm));

  }

  public static urlExists(url: string) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    if (http.status != 404)
      return true;
    else
      return false;
  }

  public static getTimestamp() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1) > 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    const day = date.getDay();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return year.toString() + month.toString() + day.toString() + hour.toString() + minutes.toString() + seconds.toString();
  }

  public static concatFloat32Array(source: Float32Array, toAdd: Float32Array) {
    var bytesPerIndex = 4,
      buffers = Array.prototype.slice.call(arguments);

    // add self
    buffers.unshift(source);

    buffers = buffers.map(() => {
      return toAdd.buffer;
    });

    var concatenatedByteLength = buffers
      .map(function (a) { return a.byteLength; })
      .reduce(function (a, b) { return a + b; }, 0);

    var concatenatedArray = new Float32Array(concatenatedByteLength / bytesPerIndex);

    var offset = 0;
    buffers.forEach(function (buffer, index) {
      concatenatedArray.set(new Float32Array(buffer), offset);
      offset += buffer.byteLength / bytesPerIndex;
    });

    return concatenatedArray;
  };

  public static async blobToAudioBuffer(blob: Blob): Promise<AudioBuffer> {
    return new Promise((resolve, reject) => {
      const audioContext = new AudioContext();
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const arrayBuffer = fileReader.result as ArrayBuffer
        audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
          resolve(audioBuffer);
        });
      }
      fileReader.onerror = () => {
        reject();
      };
      fileReader.readAsArrayBuffer(blob)
    })
  }

  public static audioBufferToBlob(audioBuffer: AudioBuffer) {

    var channelData = [],
      totalLength = 0,
      channelLength = 0;

    for (var i = 0; i < audioBuffer.numberOfChannels; i++) {
      channelData.push(audioBuffer.getChannelData(i));
      totalLength += channelData[i].length;
      if (i == 0) channelLength = channelData[i].length;
    }

    // interleaved
    const interleaved = new Float32Array(totalLength);

    for (
      let src = 0, dst = 0;
      src < channelLength;
      src++, dst += audioBuffer.numberOfChannels
    ) {
      for (var j = 0; j < audioBuffer.numberOfChannels; j++) {
        interleaved[dst + j] = channelData[j][src];
      }
      //interleaved[dst] = left[src];
      //interleaved[dst + 1] = right[src];
    }

    // get WAV file bytes and audio params of your audio source
    const wavBytes = getWavBytes(interleaved.buffer, {
      isFloat: true, // floating point or 16-bit integer
      numChannels: audioBuffer.numberOfChannels,
      sampleRate: audioBuffer.sampleRate,
    });
    const wav = new Blob([wavBytes], { type: "audio/wav" });
    return wav;

    // Returns Uint8Array of WAV bytes
    function getWavBytes(buffer: any, options: any) {
      const type = options.isFloat ? Float32Array : Uint16Array
      const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT

      const headerBytes = getWavHeader(Object.assign({}, options, { numFrames }))
      const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);

      // prepend header, then add pcmBytes
      wavBytes.set(headerBytes, 0)
      wavBytes.set(new Uint8Array(buffer), headerBytes.length)

      return wavBytes
    }

    // adapted from https://gist.github.com/also/900023
    // returns Uint8Array of WAV header bytes
    function getWavHeader(options: any) {
      const numFrames = options.numFrames
      const numChannels = options.numChannels || 2
      const sampleRate = options.sampleRate || 44100
      const bytesPerSample = options.isFloat ? 4 : 2
      const format = options.isFloat ? 3 : 1

      const blockAlign = numChannels * bytesPerSample
      const byteRate = sampleRate * blockAlign
      const dataSize = numFrames * blockAlign

      const buffer = new ArrayBuffer(44)
      const dv = new DataView(buffer)

      let p = 0

      function writeString(s: any) {
        for (let i = 0; i < s.length; i++) {
          dv.setUint8(p + i, s.charCodeAt(i))
        }
        p += s.length
      }

      function writeUint32(d: any) {
        dv.setUint32(p, d, true)
        p += 4
      }

      function writeUint16(d: any) {
        dv.setUint16(p, d, true)
        p += 2
      }

      writeString('RIFF')              // ChunkID
      writeUint32(dataSize + 36)       // ChunkSize
      writeString('WAVE')              // Format
      writeString('fmt ')              // Subchunk1ID
      writeUint32(16)                  // Subchunk1Size
      writeUint16(format)              // AudioFormat https://i.stack.imgur.com/BuSmb.png
      writeUint16(numChannels)         // NumChannels
      writeUint32(sampleRate)          // SampleRate
      writeUint32(byteRate)            // ByteRate
      writeUint16(blockAlign)          // BlockAlign
      writeUint16(bytesPerSample * 8)  // BitsPerSample
      writeString('data')              // Subchunk2ID
      writeUint32(dataSize)            // Subchunk2Size

      return new Uint8Array(buffer)
    }
  }


  // Function to convert Float32Array to WAV blob
  public static async float32ArrayToWavBlob(float32Array: Float32Array, sampleRate: number, nChannels: number = 1) {
    // Create an audio buffer from the Float32Array
    const audioBuffer = new AudioBuffer({
      length: float32Array.length,
      numberOfChannels: nChannels,
      sampleRate: sampleRate,
    });

    // Copy data to the audio buffer
    audioBuffer.getChannelData(0).set(float32Array);

    // Encode the audio buffer to WAV format
    const wavBuffer = await WavEncoder.encode(audioBuffer, {
      float32: true, // Set to true if input data is in float32 format
    });

    // Create a Blob from the WAV buffer
    const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });

    return wavBlob;
  }

}
