import { AfterContentInit, AfterViewInit, Component, ElementRef, EnvironmentInjector, HostListener, OnDestroy, ViewChild } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import { AsyncService } from '../../core/async/async.service';
import { Utils } from '../../core/utils/utils';
import { DisplayService } from '../../core/display/display.service';
import { faCircle, faPlay, faStop, faCloudArrowDown, faBolt, faFloppyDisk, faMicrophone, faRemove, faCheck, faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import KeenSlider, { KeenSliderInstance, KeenSliderPlugin } from 'keen-slider'
import { CommonService } from '../common.service';
var toWav = require('audiobuffer-to-wav')

declare var wasm_denoise_stream: any;
declare var wasm_denoise_stream_perf: any;
declare var flattenArray: any;

export interface IVoiceModel {
  model: string
  caption: string
  thumbnail: string,
  mode: string,
  pitch: string
}

@Component({
  selector: 'app-converter',
  templateUrl: 'converter.component.html',
  styleUrls: ['./converter.component.scss']
})
export class ConverterComponent implements AfterViewInit, OnDestroy, AfterContentInit {
  @ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>
  @ViewChild("thumbnailRef") thumbnailRef!: ElementRef<HTMLElement>
  @ViewChild("micRef") micRef!: ElementRef<HTMLElement>

  maxDuration: number = 120;
  mode: string = "parselmouth";
  pitch: string = "auto";
  singing: boolean = false;
  models: IVoiceModel[] = [
    { model: "scotti", caption: 'Presentatore', thumbnail: "assets/model_images/presentatore_icon_round.png", mode: this.mode, pitch: this.pitch },
    { model: "ferragni", caption: 'Influencer di Moda', thumbnail: "assets/model_images/influencer_icon_round.png", mode: this.mode, pitch: this.pitch },
    { model: "barbero", caption: 'Storico', thumbnail: "assets/model_images/storico_icon_round.png", mode: this.mode, pitch: this.pitch },
    { model: "pannofino", caption: 'Doppiatore', thumbnail: "assets/model_images/doppiatore_icon_round.png", mode: this.mode, pitch: this.pitch },
    { model: "salvini", caption: 'Politico', thumbnail: "assets/model_images/politico_icon_round.png", mode: this.mode, pitch: this.pitch },
    { model: "parodi", caption: 'Influencer di Cucina', thumbnail: "assets/model_images/cucina_icon_round.png", mode: this.mode, pitch: this.pitch },
    { model: "capone", caption: 'Documentarista', thumbnail: "assets/model_images/documentarista_icon_round.png", mode: this.mode, pitch: this.pitch },
  ];
  encoderWorker: any;
  currentFiles = [];
  gumStream: any;
  rec: any;
  input: any;
  audioContext: any;
  currentSinkId: any;
  record!: any;
  wavesurferRecorded!: WaveSurfer;
  wavesurferRecorder!: WaveSurfer;
  wavesurferTransformed!: WaveSurfer;
  currentDevice!: any;
  devices: any[] = [];
  recordedUrl: string = "";
  faCircle = faCircle;
  faPlay = faPlay;
  faStop = faStop;
  faBolt = faBolt;
  faRemove = faRemove;
  faCheck = faCheck;
  faFloppyDisk = faFloppyDisk;
  faCloudArrowDown = faCloudArrowDown;
  faMicrophone = faMicrophone;
  faCircleNotch = faCircleNotch;
  transformedUrl!: any;
  loaderMessage: string = "";
  modelsForm!: FormGroup;
  devicesForm!: FormGroup;
  recorderTimeString: string = "00:00";
  recorderTime: number = 0;
  slider!: KeenSliderInstance;
  thumbnailSlider!: KeenSliderInstance;
  currentModel: IVoiceModel = this.models[0];
  isNoiseSuppressionActive: boolean = true;
  denoisedchannel: Float32Array = new Float32Array();
  isLoadingDevices: boolean = false;
  backgroundImageUrl: string = "";
  constructor(
    private async: AsyncService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private displayService: DisplayService,
    public deviceDetector: DeviceDetectorService,
    public service: CommonService
  ) {
    this.displayService.checkScreenSize();
    this.setBackground();
    this.devicesForm = this.fb.group({
      devices: [null, Validators.required]
    });
    this.modelsForm = this.fb.group({
      models: [null, Validators.required]
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.displayService.checkScreenSize();
    this.setBackground();
  }

  ngAfterViewInit() {
    this.initModels();
    this.initSlider();
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
    if (this.thumbnailSlider) this.thumbnailSlider.destroy()
  }

  setBackground() {
    if (this.displayService.isMobile) {
      this.backgroundImageUrl = "assets/background_portrait.jpg";
    } else {
      this.backgroundImageUrl = "assets/background_landscape.jpg";
    }
  }

  initSlider() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement)
    this.thumbnailSlider = new KeenSlider(
      this.thumbnailRef.nativeElement,
      {
        initial: 0,
        slides: {
          perView: () => {
            if (this.displayService.isMobile) return 3;
            return 5;
          },
          spacing: 10,
        },
      },
      [this.thumbnailPlugin(this.slider)]
    )
  }

  thumbnailPlugin(main: KeenSliderInstance): KeenSliderPlugin {
    const context = this;
    return (slider) => {
      function removeActive() {
        slider.slides.forEach((slide) => {
          slide.classList.remove("active")
        })
      }
      function addActive(idx: number) {
        slider.slides[idx].classList.add("active")
        context.currentModel = context.models[idx];
      }

      function addClickEvents() {
        slider.slides.forEach((slide, idx) => {
          slide.addEventListener("click", () => {
            main.moveToIdx(idx)
          })
        })
      }

      slider.on("created", () => {
        addActive(slider.track.details.rel)
        addClickEvents()
        main.on("animationStarted", (main) => {
          removeActive()
          const next = main.animator.targetIdx || 0
          addActive(main.track.absToRel(next))
          slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
        })
      })
    }
  }

  initModels() {
    this.modelsForm.get('models')?.setValue(this.models[0].model);
    this.currentModel = this.models[0];
  }

  onFileSelected() {
    if (this.recordedUrl) {
      this.async.openDialog("Attenzione", "Vuoi cambiare file? Perderai quella vecchio.", (answer) => {
        if (answer) {
          this.reset(true);
          func(this);
        }
      })
    } else {
      func(this);
    }

    function func(ctx: any) {
      const inputNode: any = document.querySelector('#file');

      if (typeof (FileReader) !== 'undefined') {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const blob = new Blob([e.target.result]);
          const container = document.querySelector('#recordings') as HTMLElement
          const audio = new Audio();

          ctx.recordedUrl = URL.createObjectURL(blob);

          ctx.wavesurferRecorded = WaveSurfer.create({
            container,
            waveColor: '#00c3fa',
            progressColor: 'rgb(0, 115, 148)',
            url: ctx.recordedUrl
          })

          ctx.wavesurferRecorded.on("ready", () => {
            const duration = (ctx.wavesurferRecorded as WaveSurfer).getDuration();
            const minutes = Math.floor(duration / 60);
            const seconds = duration % 60;
            ctx.recorderTime = duration;
            ctx.recorderTimeString = `${ctx.formatTime(minutes)}:${ctx.formatTime(seconds)}`;

            if (duration > ctx.service.getMaxRecorderTime()) {
              ctx.async.openAlert("Limite Raggiunto", "Puoi usare al massimo file audio di 1 minuto con la versione free.", () => {
                ctx.reset(true);
              });
            }
          });
        };

        reader.readAsArrayBuffer(inputNode.files[0]);
      }
    }
  }

  async initDevices() {
    let calls = [];
    calls.push((() => { this.isLoadingDevices = true; return Promise.resolve(); })());
    calls.push(this.initiAudioDevices());
    calls.push(this.initRecorder());
    return Promise.all(calls)
      .then(() => {

      })
      .catch((error) => {
        this.async.openAlert("Dispositivi", "Si è verificato un problema con la ricerca del microfono. Ricarica la pagina e riprova, verifica di avere un microfono collegato. Verifica anche in Impostazioni>Impostazioni Sito>Microfono che vipvoice.it non sia bloccato.", () => { });
      })
      .finally(() => {
        this.isLoadingDevices = false;
      })
  }

  async initiAudioDevices() {
    return new Promise<void>((resolve, reject) => {
      this.devices = [];
      RecordPlugin.getAvailableAudioDevices()
        .then((devices) => {
          devices.forEach(d => {
            this.devices.push({
              deviceId: d.deviceId,
              caption: d.label || d.deviceId
            })
          })
          this.currentDevice = this.devices[0];
          this.devicesForm.get('devices')?.setValue(this.devices[0].deviceId);
          resolve();
        })
        .catch((error) => {
          reject();
        })
    })

  }

  async initRecorder() {
    return new Promise<void>((resolve, reject) => {
      this.wavesurferRecorder = WaveSurfer.create({
        container: "#mic",
        waveColor: 'rgb(200, 0, 200)',
        progressColor: 'rgb(100, 0, 100)',
        sampleRate: 44100
      })
      this.record = this.wavesurferRecorder.registerPlugin(RecordPlugin.create({ audioBitsPerSecond: 256000 }))
      this.record
        .startMic({ deviceId: "default" })
        .then(() => {
          this.record.stopMic();
          setTimeout(() => {
            let interval: any;
            this.record.on('record-end', (blob: Blob) => {
              const container = document.querySelector('#recordings') as HTMLElement
              this.recordedUrl = URL.createObjectURL(blob)
              clearInterval(interval);
              this.wavesurferRecorded = WaveSurfer.create({
                container,
                waveColor: '#00c3fa',
                progressColor: 'rgb(0, 115, 148)',
                url: this.recordedUrl,
              })
            })

            this.record.on("record-start", () => {
              this.recorderTimeString = "00:00";
              interval = setInterval(() => {
                this.recorderTime++;
                const minutes = Math.floor(this.recorderTime / 60);
                const seconds = this.recorderTime % 60;
                this.recorderTimeString = `${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
                if (this.recorderTime > this.service.getMaxRecorderTime()) {
                  clearInterval(interval);
                  this.record.stopRecording();
                  this.async.openAlert("Limite Raggiunto", "Hai al massimo 1 minuto di registrazione nella versione FREE.", () => {

                  });
                }
              }, 1000)
            })
          }, 0)
          resolve();
        })
        .catch(() => {
          reject();
        })
    });

  }

  setCurrentDevice(device: any) {
    this.currentDevice = device;
  }

  setCurrentModel(model: any) {
    this.currentModel = model;
  }

  setNativeNoiseSuppression(isActive: boolean) {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          const audioTracks = stream.getAudioTracks();
          if ('getSettings' in audioTracks[0]) {
            audioTracks.forEach((audioTrack) => {
              const currentSettings = audioTrack.getSettings();
              currentSettings.noiseSuppression = isActive;
              audioTrack.applyConstraints(currentSettings);
            });
            console.log('Global noise suppression is set to false for all audio tracks:', audioTracks);
          } else {
            console.error('MediaTrackSettings not supported in this browser.');
          }
        })
        .catch((error) => {
          console.error('Error accessing the microphone:', error);
        });
    } else {
      console.error('getUserMedia not supported in this browser.');
    }
  }

  playPausePlayer(wavesurfer: WaveSurfer) {
    wavesurfer.playPause();
  }

  async startStopRecording() {
    if (!this.devices.length) {
      await this.initDevices();
    }
    if (this.record.isRecording()) {
      this.record.stopRecording();
      this.record.stopMic();
    } else {
      if (this.recordedUrl) {
        this.async.openDialog("Attenzione", "Vuoi rifare la registrazione? Perderai quella vecchia.", (answer) => {
          if (answer) {
            this.reset(true);
            this.record.startRecording({ deviceId: this.currentDevice.deviceId })
          }
        })
      } else {
        this.record
          .startRecording({ deviceId: this.currentDevice.deviceId });
      }
    }

  }

  downloadOriginalAudio() {
    let now = new Date();
    let fileName = "Registrazione_" + now + ".m4a"
    Utils.downloadUri(this.recordedUrl, fileName);
  }

  downloadTransformedAudio() {
    let now = new Date();
    let fileName = "Trasformazione_" + now + ".m4a"
    Utils.downloadUri(this.transformedUrl, fileName);
  }

  playPauseRecorder() {
    if (this.record.isPaused()) {
      this.record.resumeRecording()
    } else {
      this.record.pauseRecording()
    }
  }

  hasRecordings() {
    return this.recordedUrl.trim().length;
  }

  hasTransformed() {
    return !!this.transformedUrl;
  }

  getDuration(src: any) {
    // var audio = new Audio();
    // return new Promise((resolve, reject) => {
    //   $(audio).on("loadedmetadata", function () {
    //     resolve(audio.duration)
    //   });
    //   audio.src = src;
    // })
  }

  getWaveformColor() {
    const ctx = document.createElement('canvas').getContext('2d')
    const gradient = ctx?.createLinearGradient(0, 0, 0, 128);
    gradient?.addColorStop(0, 'rgb(200, 0, 200)')
    gradient?.addColorStop(1, '#00C3FA');
    return gradient as CanvasGradient & string;
  }

  async processNoiseSuppression(blob: Blob) {
    if (!this.audioContext)
      this.audioContext = new AudioContext();
    let audioBuffer = await Utils.blobToAudioBuffer(blob);
    let denoisedBuffer = wasm_denoise_stream(audioBuffer.getChannelData(0))
    let denoisedAudioBuffer = this.audioContext.createBuffer(1, audioBuffer.length, audioBuffer.sampleRate);
    denoisedAudioBuffer.copyToChannel(denoisedBuffer, 0, 0);
    return Utils.audioBufferToBlob(denoisedAudioBuffer);
  }

  async transform() {
    this.ngxLoader.start();
    this.loaderMessage = "Trasformazione in corso... (2 minuti circa)"
    let blob = await fetch(this.recordedUrl).then(r => r.blob());
    if (this.isNoiseSuppressionActive) blob = await this.processNoiseSuppression(blob);
    this.upload(blob)
      .then((result) => {
        this.transformedUrl = URL.createObjectURL(result as Blob);
        if (this.wavesurferTransformed) this.wavesurferTransformed.destroy();
        this.wavesurferTransformed = WaveSurfer.create({
          container: "#transformed",
          waveColor: '#00c3fa',
          progressColor: 'purple',
          url: this.transformedUrl,
        })
        this.wavesurferTransformed.options.waveColor = this.getWaveformColor();
      })
      .then(() => {
        this.toastr.success("Il tuo audio è pronto!");
      })
      .catch(() => {
        this.toastr.error("Ops, qualcosa è andato storto :(");

      })
      .finally(() => {
        this.ngxLoader.stop();
        this.loaderMessage = "";
      })
  }

  async upload(blob: Blob) {
    const model = this.currentModel.model;
    const mode = this.currentModel.mode;
    const pitch = this.singing ? "" : "auto";
    const file = new File([blob], "filename.mp3");
    const reader = new FileReader();
    const formData = new FormData();
    return new Promise((resolve, reject) => {
      reader.onload = function () {
        formData.append("file", file);
        formData.append("model", model);
        formData.append("mode", mode);
        formData.append("pitch", pitch);
        fetch("https://inference-sync-dyvmow2yza-uc.a.run.app/predict", {
          method: "post",
          body: formData,
        })
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            resolve(blob)
          }, reject)
      };
      reader.readAsArrayBuffer(file);
    })
  }

  reset(force: boolean = false) {
    if (force) {
      reset(this);
    } else {
      this.async.openDialog("Attenzione", "Vuoi rimuovere la registrazione?", (answer) => {
        if (answer) {
          reset(this);
        }
      })
    }

    function reset(ctx: any) {
      ctx.transformedUrl = "";
      ctx.recordedUrl = "";
      ctx.recorderTime = 0;
      ctx.recorderTimeString = "00:00";
      ctx.wavesurferRecorded ? ctx.wavesurferRecorded.destroy() : null;
      ctx.wavesurferTransformed ? ctx.wavesurferTransformed.destroy() : null;
      ctx.denoisedchannel = new Float32Array();
    }
  }

  private formatTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

}