<div class="container">
    <div class="row" id="functionality">
        <div class="keen-slider" id="slider">
            <div class="keen-slider__slide number-slide1">
                <div class="slider-image slider-image-1"></div>
            </div>
            <div class="keen-slider__slide number-slide2">
                <div class="slider-image slider-image-2"></div>

            </div>
        </div>
    </div>

    <div class="row m-5">
        <div class="col text-center">
            <h1>Perché Vip Voice è la Scelta Migliore?</h1>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 col-sm-12 text-center" style="margin-bottom: 15px">
            <fa-icon [icon]="faBolt" size="3x"></fa-icon>
            <h2>Alta Qualità</h2>
            <div>Con la nostra avanzata tecnologia assicuriamo una qualità impareggiabile nella modifica vocale. </div>
        </div>
        <div class="col-md-4 col-sm-12 text-center" style="margin-bottom: 15px">
            <fa-icon [icon]="faFaceSmile" size="3x"></fa-icon>
            <h2>Facile da Usare</h2>
            <div> Carica o registra direttamente dalla nostra piattaforma. La modifica della voce non è mai stata così
                semplice.</div>
        </div>
        <div class="col-md-4 col-sm-12 text-center" style="margin-bottom: 15px">
            <fa-icon [icon]="faDollarSign" size="3x"></fa-icon>
            <h2>Versione Free</h2>
            <div>Sperimenta la potenza di Vip Voice con un minuto di trasformazione gratuita per ogni file audio.</div>
        </div>
    </div>

    <div class="row m-5">
        <div class="col text-center">
            <h1>Scopri le Potenzialità di Vip Voice</h1>
        </div>
    </div>

    <div class="row">
        <mat-vertical-stepper>
            <mat-step label="Carica o Registra">
                <div>Direttamente dalla nostra piattaforma, senza bisogno di software aggiuntivi. </div>
            </mat-step>
            <mat-step label="Tecnologia All'avanguardia">
                <div>Garanziamo deepfake vocali di alta qualità.</div>
            </mat-step>
            <mat-step label="Opzioni di Personalizzazione">
                <div>Scegli tra varie opzioni per ottenere la voce desiderata.</div>
            </mat-step>
            <mat-step label="Sicurezza">
                <div> I tuoi file sono al sicuro con noi e vengono eliminati dopo l'elaborazione.</div>
            </mat-step>
        </mat-vertical-stepper>
    </div>
    
    <div class="row m-5" id="pricing">
        <div class="col text-center">
            <h1>Scegli il piano giusto per te</h1>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 col-sm-12 mb-3">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Freemium</mat-card-title>
                    <mat-card-subtitle>Gratuito</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item>
                            <mat-icon matListItemIcon>done</mat-icon>
                            <div matListItemTitle>1 minuto di trasformazione per file/registrazione</div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <mat-icon matListItemIcon>close</mat-icon>
                            <div matListItemTitle>Funzionalità avanzate</div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <mat-icon matListItemIcon>close</mat-icon>
                            <div matListItemTitle>Supporto dedicato</div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-button>GRATIS</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-md-6 col-sm-12 mb-3">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Basic</mat-card-title>
                    <mat-card-subtitle> €7/mese</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item>
                            <mat-icon matListItemIcon>done</mat-icon>
                            <div matListItemTitle>4 minuti di trasformazione per file/registrazione</div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <mat-icon matListItemIcon>done</mat-icon>
                            <div matListItemTitle>Funzionalità avanzate</div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <mat-icon matListItemIcon>close</mat-icon>
                            <div matListItemTitle>Supporto dedicato</div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-button [disabled]="buttonsDisabled">INIZIA ORA</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-sm-12 mb-3">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Creator</mat-card-title>
                    <mat-card-subtitle> €15/mese</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item>
                            <mat-icon matListItemIcon>done</mat-icon>
                            <div matListItemTitle>10 minuti di trasformazione per file/registrazione</div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <mat-icon matListItemIcon>done</mat-icon>
                            <div matListItemTitle>Funzionalità avanzate</div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <mat-icon matListItemIcon>done</mat-icon>
                            <div matListItemTitle>Supporto dedicato</div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-button [disabled]="buttonsDisabled">INIZIA ORA</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-md-6 col-sm-12 mb-3">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Pro</mat-card-title>
                    <mat-card-subtitle>€29/mese</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item>
                            <mat-icon matListItemIcon>done</mat-icon>
                            <div matListItemTitle>Trasformazioni illimitate</div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <mat-icon matListItemIcon>done</mat-icon>
                            <div matListItemTitle>Funzionalità avanzate</div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <mat-icon matListItemIcon>done</mat-icon>
                            <div matListItemTitle>Supporto dedicato</div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-button [disabled]="buttonsDisabled">INIZIA ORA</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    <div class="m-5"></div>

</div>