import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, APP_INITIALIZER, ErrorHandler, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Inject, Injectable } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LandingComponent } from './components/landing/landing.component';
import { ConverterComponent } from './components/converter/converter.component';
import { HomeComponent } from './components/home/home.component';
import { CommonService } from './components/common.service';
import { NavigationComponent } from './components/navigation/navigation.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from './core/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthorizedComponent } from './components/authorized/authorized.component';
import { TermsComponent } from './components/terms/terms.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AsyncDialogComponent} from './core/async/dialog/async.dialog.component';
import { AsyncAlertComponent} from './core/async/alert/async.alert.component';
import { AsyncEditorComponent} from './core/async/editor/async.editor.component';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AsyncService } from './core/async/async.service';
import { LocalStorageService } from './core/localStorage/local.storage.service';
import { DisplayService } from './core/display/display.service';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatProgressBarModule,
    MatFormFieldModule, 
    MatChipsModule, 
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatSliderModule,
    FontAwesomeModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatNativeDateModule,
    MatCardModule,
    RouterModule,
    NgxUiLoaderModule.forRoot({
      fgsColor: 'white',
      pbColor: 'white',
      fgsType: "circle",
      fgsSize: 70,
      minTime: 400
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LandingComponent,
    ConverterComponent,
    ForbiddenComponent,
    NavigationComponent,
    SignInComponent,
    AuthorizedComponent,
    UnauthorizedComponent,
    AsyncDialogComponent,
    AsyncAlertComponent,
    AsyncEditorComponent,
    TermsComponent,
  ],
  providers: [
    AsyncService,
    LocalStorageService,
    DisplayService,
    CommonService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
