import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faFaceSmile, faDollarSign, faBolt } from "@fortawesome/free-solid-svg-icons"
import KeenSlider, { KeenSliderInstance } from 'keen-slider'
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-landing',
  templateUrl: 'landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  faFaceSmile = faFaceSmile;
  faDollarSign = faDollarSign;
  faBolt = faBolt;
  slider!: KeenSliderInstance;
  buttonsDisabled: boolean = true;
  
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.fragment.subscribe((f: any) => {
      const element = document.querySelector("#" + f)
      if (element) element.scrollIntoView()
    })

    this.slider = new KeenSlider(
      "#slider",
      {
        loop: true
      },
      [
        (slider) => {
          let timeout: any;
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 2000)
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        },
      ]
    )
  }

}