import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { faGoogle, faFacebook, faApple } from '@fortawesome/free-brands-svg-icons';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})

export class SignInComponent implements OnInit {
  faGoogle = faGoogle;
  faFacebook = faFacebook;
  faApple = faApple;
  error: string = "";

  constructor(public authService: AuthService) {}

  ngOnInit() { }

  signIn() {

  }

  goToTermsAndConditionsPolicy(){
    window.location.href = "https://www.iubenda.com/privacy-policy/47229252";

  }

  goToPrivacyPolicy(){
    window.location.href = "https://www.iubenda.com/privacy-policy/87379399";

  }
}
