import { User } from "@angular/fire/auth";
import { Timestamp } from "@angular/fire/firestore";

export interface IUser {
  uid: string;
  name: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  authProviders: string[];
}


export interface IUserPrivate {
  deviceId: string;
  lastUpdate: Date;
  token: string;
}

export class UserPrivate implements IUserPrivate {
  constructor(deviceId: string, token: string) {
    this.deviceId = deviceId;
    this.token = token;
  }
  deviceId: string;
  lastUpdate!: Date;
  token: string;
}

export class AcusticaSubscription {
  Active!: boolean;
  Description!: string;
  EndDate!: number;
  ManageSubscriptionsUrl!: string;
  Name!: string;
  Sku!: string;
  StartDate!: number;
  SubscriptionId!: string;
  SubscriptionKey!: string;
}

export class AcquariusUser {
  email!: string;
  email_verified!: boolean;
  nickname!: string;
  preferred_username!: string;
  sub!: string;
}

export class UserUtils {
  public static userPrivateToFirebaseModel(userPrivate: IUserPrivate) {
    let obj = Object.assign({}, userPrivate) as any;
    obj.lastUpdate = Timestamp.fromDate(userPrivate.lastUpdate);
    return obj;
  }

  public static userToFirebaseModel(user: IUser) {
    let obj = Object.assign({}, user) as any;
    if (!obj.name) obj.name = obj.displayName;
    if (!obj.photoURL) delete obj.photoURL;
    return obj;
  }
}