<div class="container text-center">
    <h2> Termini e Condizioni d'Uso per i servizi di conversione vocali offerti da Vipvoice.it</h2>
    <div>
        <h3>
            1. Accettazione dei Termini
        </h3>
        <div>
            1.1 L'utilizzo della web app di conversione vocale ("Servizio") offerta da Vipvoice.it ("Noi" o "Fornitore")
            è
            soggetto all'accettazione incondizionata e completa dei seguenti Termini e Condizioni d'Uso ("Termini").
            L'utente ("Utente" o "Tu") è tenuto a leggere attentamente questi Termini prima di utilizzare il Servizio.
            L'accesso e l'utilizzo del Servizio costituiscono una completa accettazione di questi Termini.
        </div>
        <br>
        <h3>
            2. Descrizione del Servizio
        </h3>
        <div>
            2.1 Il Servizio offre agli Utenti la possibilità di convertire i propri file audio e registrazioni nella
            voce di
            personaggi famosi utilizzando i modelli forniti da Vipvoice.it e implementati su Google Cloud Run.
        </div>
        <h3>
            3. Utilizzo Consentito
        </h3>
        <br>
        <div>
            3.1 L'Utente accetta di utilizzare il Servizio solo per scopi leciti e in conformità con tutti i regolamenti
            e
            le leggi applicabili.
        </div>
        <div>
            3.2 L'Utente comprende e accetta che l'uso del Servizio per impersonare personaggi famosi o per qualsiasi
            attività illegale o immorale è strettamente proibito.
        </div>
        <br>
        <h3>
            4. Proprietà Intellettuale
        </h3>
        <div>
            4.1 Tutti i modelli, algoritmi e contenuti forniti tramite il Servizio sono di proprietà esclusiva di
            Vipvoice.it. L'Utente riconosce e accetta di non copiare, riprodurre o distribuire alcun componente del
            Servizio
            senza il consenso scritto di Vipvoice.it.
        </div>
        <br>
        <h3>
            5. Uso Improprio
        </h3>
        <div>
            5.1 L'Utente si impegna a non utilizzare il Servizio per scopi che potrebbero essere considerati
            diffamatori,
            offensivi, fraudolenti, o che possano comportare un danno all'immagine di Vipvoice.it.

        </div>
        <div>
            5.2 L'Utente comprende e accetta che l'uso del Servizio per impersonare personaggi famosi o per qualsiasi
            forma
            di inganno è vietato e può comportare la sospensione immediata dell'account e azioni legali.
        </div>
        <br>
        <h3>
            6. Limitazione di Responsabilità
        </h3>
        <div>
            6.1 Vipvoice.it non è responsabile per qualsiasi danno diretto, indiretto, accidentale, speciale o
            consequenziale derivante dall'utilizzo del Servizio o da eventuali interruzioni o errori nel Servizio.
        </div>
        <br>
        <h3>
            7. Sospensione e Cancellazione dell'Account
        </h3>
        <div>
            7.1 Vipvoice.it si riserva il diritto di sospendere o cancellare l'account di un Utente in qualsiasi momento
            e
            per qualsiasi motivo, a sua esclusiva discrezione.
        </div>
        <br>
        <h3>
            8. Modifiche ai Termini
        </h3>
        <div>
            8.1 Vipvoice.it si riserva il diritto di modificare questi Termini in qualsiasi momento. Le modifiche
            saranno
            effettive immediatamente dopo la pubblicazione degli aggiornamenti. L'Utente è tenuto a verificare
            periodicamente eventuali modifiche.
        </div>
        <br>
        <h3>
            9. Legge Applicabile e Giurisdizione
        </h3>
        <div>
            9.1 Questi Termini sono regolati e interpretati in conformità con le leggi dello Stato in cui Vipvoice.it ha
            la
            propria sede legale. Qualsiasi controversia derivante o in relazione a questi Termini sarà sottoposta alla
            giurisdizione esclusiva dei tribunali competenti nello stesso Stato.

            Accettando questi Termini, l'Utente conferma di aver letto, compreso e accettato tutte le disposizioni in
            essi
            contenute. L'Utente accetta inoltre di conformarsi a tutti i regolamenti e alle leggi applicabili durante
            l'utilizzo del Servizio.
        </div>
        <br>
        <div>
            Questa applicazione utilizza la tecnologia di intelligenza artificiale per creare deepfake vocali di
            personaggi
            famosi italiani a fini satirici e umoristici. In conformità con gli articoli 4, 5, 6, 7, 8, 9 e 13 del
            REGOLAMENTO DEL PARLAMENTO EUROPEO E DEL CONSIGLIO
            CHE STABILISCE REGOLE ARMONIZZATE SULL'INTELLIGENZA ARTIFICIALE (LEGGE SULL'INTELLIGENZA ARTIFICIALE) E
            MODIFICA
            ALCUNI ATTI LEGISLATIVI DELL'UNIONE, questa applicazione si impegna a rispettare tutte le normative
            dell'Unione
            Europea in merito ai sistemi di intelligenza artificiale, inclusi quelli considerati ad alto rischio.
        </div>
        <br>
        <div>
            Si enfatizza che i deepfake generati da questa applicazione non devono essere utilizzati per scopi proibiti
            dall'articolo 5, come tecniche subliminali o sfruttamento di vulnerabilità di specifici gruppi di persone.
            L'uso
            dei deepfake è strettamente limitato a scopi satirici e umoristici, nel rispetto dei requisiti di
            trasparenza e
            informazione agli utenti delineati nell'articolo 13.
        </div>
        <br>
        <div>
            L'utente si assume la piena responsabilità per l'uso dei deepfake generati e si impegna a non utilizzarli
            per
            scopi diffamatori, ingannevoli, offensivi o illegali. L'applicazione non si assume alcuna responsabilità per
            l'uso improprio o illegale dei deepfake generati.
        </div>
        <br>
        <div>
            L'uso di questa applicazione implica l'accettazione di questi termini e delle responsabilità connesse.
        </div>
        <br>
        <div>
            Articolo 4: Questo articolo si riferisce al potere della Commissione di adottare atti delegati per
            modificare
            l'elenco delle tecniche e degli approcci di IA in risposta agli sviluppi tecnologici e di mercato​​.

        </div>
        <br>
        <div>
            Articolo 5: Questo articolo stabilisce pratiche di IA vietate, come l'uso di sistemi di IA che utilizzano
            tecniche subliminali per distorcere il comportamento o sfruttare vulnerabilità di specifici gruppi di
            persone​​.
        </div>
        <br>
        <div>
            Articolo 6 e Articolo 7: Questi articoli definiscono i sistemi di IA considerati "ad alto rischio" e
            stabiliscono i criteri per la loro classificazione​​.
        </div>
        <br>
        <div>
            Articolo 8 e Articolo 9: Si occupano della conformità ai requisiti per i sistemi di IA ad alto rischio e
            dell'istituzione di un sistema di gestione dei rischi​​.
        </div>
        <div>
            Articolo 13: Tratta della trasparenza e della fornitura di informazioni agli utenti di sistemi di IA ad alto
            rischio, inclusi dettagli sull'identità del fornitore, sulle caratteristiche del sistema e sulle istruzioni
            per
            l'uso​​.
        </div>
        <br>
        <br>
    </div>
</div>