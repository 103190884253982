// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: "beta-0.1",
  production: true,
  clientName: "Vip Voice",
  defaultUrl: "https://www.vipvoice.it",
  endpoints: {
    'Fabio.Sevice': ""
  },
  acustica: {
    storeUrl: "https://www.acustica-audio.com",
    storageSizeFree: 2000, //Size in MB
    storageSizeTier1: 1000000
  },
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebase: {
    apiKey: "AIzaSyAOnrDClAoTJpxTuNj7hMDPHppVDovz9XY",
    authDomain: "sienna-sphere-production.firebaseapp.com",
    databaseURL: "https://sienna-sphere-production-default-rtdb.firebaseio.com",
    projectId: "sienna-sphere-production",
    storageBucket: "sienna-sphere-production.appspot.com",
    messagingSenderId: "299868859070",
    appId: "1:299868859070:web:2a402cb9265c80b0604cef",
    measurementId: "G-GB6G475NZB",
    vapidKey: "BP4GdxJQnlkwZ_Ac04XLCH-0nTFJM2riAcV7ti8K4a799_uRFUBZRbW5OL_cqKRFQn88Jx5z9yZlwZ3Ax7gVPwE",
    dynamicLinkInfo: {
      domainUriPrefix: "https://links.siennasphere.acustica-audio.com",
      iosInfo: {
        iosBundleId: "com.acusticaudio.siennasphere",
        iosAppStoreId: "6443596378",
        iosCustomScheme: "sienna-sphere"
      }
    },
    endpoints: {
      dynamicLink: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key="
    }, 
    googleDriveClientId: "299868859070-7600rhr8acntg6d58u2ph6uc0rfcevek.apps.googleusercontent.com",
    clientFoo: "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"
  },
  dropbox: {
    apiKey: "yrm3w3nxigil5ef",
    redirectUri: location.origin + "/bootstrap"
  },
  providers: {
    GOOGLE: "google.com",
    FACEBOOK: "facebook.com",
    KEYCLOAK: "oidc.keycloak",
    APPLE: "apple.com",
  },
  dbConfig: {
    name: 'SiennaSphere',
    version: 2,
    objectStoresMeta: [{
      store: 'versions',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'key', keypath: 'key', options: { unique: true } },
        { name: 'blob', keypath: 'blob', options: { unique: false } }
      ]
    }]
  },
  keycloack: {
    authority: 'https://auth.acustica-audio.com/auth/realms/acustica-audio'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
