import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AsyncAlertComponent } from "./alert/async.alert.component";
import { AsyncDialogComponent } from "./dialog/async.dialog.component";
import { AsyncEditorComponent } from "./editor/async.editor.component";

@Injectable({
  providedIn: 'root'
})
export class AsyncService {

  constructor(public dialog: MatDialog) { }

  openDialog(title: string, message: string, callback: (result: any) => void): void {
    const dialogRef = this.dialog.open(AsyncDialogComponent, {
      //width: '250px',
      data: { title, message },
    });

    dialogRef.afterClosed().subscribe(result => {
      callback(result);
    });
  }

  openAlert(title: string, message: string, callback: (result: any) => void): void {
    const dialogRef = this.dialog.open(AsyncAlertComponent, {
      //width: '250px',
      data: { title, message },
    });

    dialogRef.afterClosed().subscribe(result => {
      callback(result);
    });
  }


  openEditor(title: string, message: string, fieldName: string, field: any, callback: (result: any) => void): void {
    const dialogRef = this.dialog.open(AsyncEditorComponent, {
      width: '250px',
      data: {
        title,
        message,
        field,
        fieldName
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      callback(result);
    });
  }

}
