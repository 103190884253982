<video autoplay muted loop class="video-background">
  <source src="/assets/background.mp4" type="video/mp4">
</video>
<div class="video-overlay"></div>
<div class="container flex-center signin-container">
  <img class="signin-logo" src="/assets/logo.png" alt="Sienna Sphere" />
  <mat-card class="card-container">
    <mat-card-title></mat-card-title>
    <mat-card-content>
      <form>
        <!--<p>
          <mat-form-field>
            <input type="text" matInput placeholder="Username" formControlName="username" #userName>
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="password" matInput placeholder="Password" formControlName="password" #userPassword>
          </mat-form-field>
        </p>

        <p *ngIf="error" class="error">
          {{ error }}
        </p>-->

        <div class="button-container">
          <!--<button type="submit" color="primary" mat-raised-button (click)="authService.SignIn(userName.value, userPassword.value)">Login</button>-->
          <img src="assets/google_signin_button.png" alt="" (click)="authService.loginGoogle()">
          <button type="submit" color="primary" mat-raised-button (click)="authService.loginFacebook()">
            <fa-icon [icon]="faFacebook">k</fa-icon> Login with Facebook
          </button>
          <button type="submit" color="primary" mat-raised-button (click)="authService.loginApple()">
            <fa-icon [icon]="faApple"></fa-icon> Login with Apple
          </button>
          <button type="submit" color="primary" mat-raised-button (click)="authService.loginKeycloak()">
            <img src="assets/logo_acustica.png" referrerpolicy="no-referrer" alt="Acusticaudio Logo" />
            Login with Aquarius
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <span>Sienna app Copyright 2023</span>
  <button color="primary" mat-button (click)="goToPrivacyPolicy()">
    Privacy policy
  </button>
</div>
