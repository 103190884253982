<div class="container">
    <div class="m-5"></div>
    <div class="row">
        <mat-card [style.background]="'linear-gradient(rgba(63, 29, 185, 0.8), rgba(63, 29, 185, 0.8)), url(' + backgroundImageUrl + ')'">
            <mat-card-content class="d-flex justify-content-center flex-column">
                <div class="d-flex flex-column align-items-center col">
                    <h2 class="text-center">Scegli la Tua Voce</h2>
                </div>
                <div #sliderRef class="d-flex keen-slider">
                    <div *ngFor="let model of models; let i = index" [class]="'keen-slider__slide number-slide' + i">
                        <img [src]="model.thumbnail" [alt]="model.caption" [class]="'slider-image slider-image' + i">
                    </div>
                </div>
                <span class="text-center">
                    <strong>{{currentModel.caption}}</strong>
                </span>
                <div #thumbnailRef class="d-flex keen-slider thumbnail">
                    <div *ngFor="let model of models; let i = index" [class]="'keen-slider__slide number-slide' + i">
                        <fa-icon class="thumbnail-icon" [hidden]="model != currentModel" [icon]="faCheck"></fa-icon>
                        <img [src]="model.thumbnail" [alt]="model.caption" [class]="'slider-image slider-image' + i">
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="m-5"></div>
    <div class="row">
        <mat-card [style.background]="'linear-gradient(rgba(63, 29, 185, 0.8), rgba(63, 29, 185, 0.8)), url(' + backgroundImageUrl + ')'">
            <mat-card-content>
                <mat-tab-group mat-stretch-tabs>
                    <mat-tab label="Registra">
                        <div class="container" style="overflow-y: hidden;">
                            <div class="row">
                                <div class="col">
                                    <h2 class="text-center m-2">
                                        Registra un Audio
                                    </h2>
                                </div>
                            </div>
                            <div class="row" style="overflow-y: hidden;">
                                <div class="col">
                                    <div id="mic" #micRef></div>
                                    <div class="w-100 d-flex justify-content-center" *ngIf="!deviceDetector.isMobile() && devices.length">
                                        <form [formGroup]="devicesForm" style="width:80%">
                                            <mat-form-field class="w-100">
                                                <mat-label>Dispositivi di Registrazione</mat-label>
                                                <mat-select formControlName="devices">
                                                    <mat-option (click)="setCurrentDevice(device)"
                                                        *ngFor="let device of devices" [value]="device.deviceId">
                                                        {{device.caption}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </form>
                                    </div>
                                    <div class="w-100 d-flex justify-content-center">
                                        <!-- <button [hidden]="devices.length" (click)="initDevices()" mat-fab
                                            color="warn">
                                            <fa-icon [icon]="faMicrophone" *ngIf="!isLoadingDevices"></fa-icon>
                                            <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="isLoadingDevices"></fa-icon>
                                        </button> -->
                                        <button [hidden]="record?.isRecording()" (click)="startStopRecording()" mat-fab
                                            color="warn">
                                            <fa-icon [icon]="faMicrophone"></fa-icon>
                                        </button>
                                        <button [hidden]="!record?.isRecording()" (click)="startStopRecording()" mat-fab
                                            color="primary">
                                            <fa-icon [icon]="faStop"></fa-icon>
                                        </button>
                                    </div>
                                    <div class="m-2 d-flex justify-content-center">
                                        {{isLoadingDevices ? 'Ricerca dispositivi...' : recorderTimeString}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Carica File">
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                    <h2 class="text-center m-2">
                                        Seleziona un file Audio dal tuo dispositivo
                                    </h2>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <input hidden (change)="onFileSelected()" #fileInput type="file" accept="audio/*"
                                    id="file">
                                <button color="primary" class="" type="button" mat-fab
                                    (click)="fileInput.click()">
                                    <fa-icon [icon]="faFloppyDisk"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="m-5"></div>
    <div class="row" [hidden]="!hasRecordings()">
        <mat-card [style.background]="'linear-gradient(rgba(63, 29, 185, 0.8), rgba(63, 29, 185, 0.8)), url(' + backgroundImageUrl + ')'">
            <mat-card-content>
                <div class="container">
                    <div class="row">
                        <div style="margin-top: 20px;" class="text-center" *ngIf="hasRecordings()">
                            <h2>Il tuo audio originale</h2>
                        </div>
                        <div class="m-2 d-flex justify-content-center">
                            {{recorderTimeString}}
                        </div>
                        <div id="recordings"></div>
                        <div class="col" *ngIf="hasRecordings()">
                            <div class="w-100 d-flex justify-content-center m-2">
                                <button class="m-1" *ngIf="!wavesurferRecorded?.isPlaying()"
                                    (click)="playPausePlayer(wavesurferRecorded)" mat-fab color="primary">
                                    <fa-icon [icon]="faPlay"></fa-icon>
                                </button>
                                <button class="m-1" *ngIf="wavesurferRecorded?.isPlaying()"
                                    (click)="playPausePlayer(wavesurferRecorded)" mat-fab color="primary">
                                    <fa-icon [icon]="faStop"></fa-icon>
                                </button>
                                <button class="m-1" (click)="downloadOriginalAudio()" mat-fab color="primary">
                                    <fa-icon [icon]="faCloudArrowDown"></fa-icon>
                                </button>
                                <button class="m-1" (click)="reset()" mat-fab color="primary">
                                    <fa-icon [icon]="faRemove"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <div class="col m-2 d-flex align-items-center">
                            <mat-form-field class="m-2" *ngIf="service.isBackdoorEnabled">
                                <mat-label>Algoritmo</mat-label>
                                <mat-select [(value)]="mode">
                                    <mat-option value="parselmouth">Tipo 1</mat-option>
                                    <mat-option value="crepe">Tipo 2</mat-option>
                                    <mat-option value="crepe-tiny">Tipo 3</mat-option>
                                    <mat-option value="dio">Tipo 4</mat-option>
                                    <mat-option value="harvest">Tipo 5</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class=" m-2">
                                <mat-slide-toggle color="primary" [(ngModel)]="singing">
                                    Cantato
                                </mat-slide-toggle>
                            </div>
                            <div class="m-2">
                                <mat-slide-toggle color="primary" [(ngModel)]="isNoiseSuppressionActive">
                                    Rimuovi rumore AI
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-center align-items-center" *ngIf="hasRecordings()">
                            <div class=" m-2">
                                <button class="m-1" mat-raised-button color="primary" (click)="transform()">
                                    <fa-icon [icon]="faBolt"></fa-icon>
                                    Trasforma
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div style="margin-top: 20px;" class="text-center" *ngIf="hasTransformed()">
                            <h2>Il tuo audio trasformato</h2>
                        </div>
                        <div id="transformed"></div>
                        <div class="w-100 d-flex justify-content-center m-2" *ngIf="hasTransformed()">
                            <button class="m-1 theme-horizontal-linear-gradient"
                                *ngIf="!wavesurferTransformed?.isPlaying()"
                                (click)="playPausePlayer(wavesurferTransformed)" mat-fab color="accent">
                                <fa-icon [icon]="faPlay"></fa-icon>
                            </button>
                            <button class="m-1 theme-horizontal-linear-gradient"
                                *ngIf="wavesurferTransformed?.isPlaying()"
                                (click)="playPausePlayer(wavesurferTransformed)" mat-fab color="accent">
                                <fa-icon [icon]="faStop"></fa-icon>
                            </button>
                            <button class="m-1 theme-horizontal-linear-gradient" (click)="downloadTransformedAudio()"
                                mat-fab color="accent">
                                <fa-icon [icon]="faCloudArrowDown"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="m-5"></div>
</div>

<ngx-ui-loader [text]="loaderMessage"></ngx-ui-loader>