<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav theme-vertical-linear-gradient-inverted" fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) ? 'false' : 'true'">
    <mat-nav-list class="navigation-nav-list">
      <div>
        <a mat-list-item routerLink="/home" routerLinkActive="active">
          <div class="navigation-menu-item">
            <fa-icon [icon]="faMicrophone"></fa-icon>
            <div>Converti</div>
          </div>
        </a>
      </div>
      <div class="navigation-menu-items">
        <a mat-list-item routerLink="/landing" fragment="functionality">
          <div class="navigation-menu-item">
            <fa-icon [icon]="faListCheck"></fa-icon>
            <div>Funzionalità</div>
          </div>
        </a>
        <!-- <a mat-list-item routerLink="/landing" fragment="faq">
          <div class="navigation-menu-item">
            <fa-icon [icon]="faQuestionCircle"></fa-icon>
            <div>FAQ</div>
          </div>
        </a> -->
        <a mat-list-item routerLink="/landing" fragment="pricing">
          <div class="navigation-menu-item">
            <fa-icon [icon]="faDollarSign"></fa-icon>
            <div>Prezzi</div>
          </div>
        </a>
        <a mat-list-item routerLink="/terms">
          <div class="navigation-menu-item">
            <fa-icon [icon]="faHeart"></fa-icon>
            <div>Condizioni d'uso</div>
          </div>
        </a>
        <a mat-list-item href="mailto:info@vipvoice.it">
          <div class="navigation-menu-item">
            <fa-icon [icon]="faFaceSmile"></fa-icon>
            <div>Contatti</div>
          </div>
        </a>
        <!-- <a mat-list-item (click)="logout()">
          <fa-icon [icon]="faSignOut"></fa-icon>
          <div>Logout</div>
        </a> -->
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="toolbar-container">
        <div class="toolbar-logo m-2">
          <img [src]="logoUrl" alt="Vip Voice Logo" (click)="enableBackdoor()"/>
        </div>
        <!-- <button [disabled]="!hasBackNavigation()" mat-icon-button (click)="goBack()">
          <fa-icon [icon]="faArrowLeft"></fa-icon>
        </button>
        <button [disabled]="!hasForwardNavigation()" mat-icon-button (click)="goForward()">
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </button> -->
        <!-- <div class="toolbar-icons">
          <div class="alpha-tag" (click)="onAlphaTagClick()">{{applicationVersion}}</div>
          <app-notifications></app-notifications>
          <img *ngIf="!!authService.user.photoURL" src="{{authService.user.photoURL}}" (click)="goToSettings()"
            referrerpolicy="no-referrer" alt="Profile Photo" />
          <fa-icon *ngIf="!authService.user.photoURL" [icon]="faUser" (click)="goToSettings()"></fa-icon>
        </div> -->
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>