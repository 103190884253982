<h3 mat-dialog-title>{{data.title}}</h3>
<div mat-dialog-content>
  <div>{{data.message}}</div>
  <div class="w-100">
    <mat-form-field class="w-100">
      <mat-label>{{data.fieldName}}</mat-label>
      <input [(ngModel)]="data.field" type="text" matInput [formControl]="formControl" [errorStateMatcher]="matcher" (keyup.enter)="confirm()">
      <mat-error *ngIf="formControl.hasError('required')">
        This field is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="async-editor-actions">
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button mat-raised-button (click)="confirm()" color="accent" [disabled]="!formControl.valid" >Confirm</button>
</div>
