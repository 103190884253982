import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-async-alert',
  templateUrl: 'async.alert.component.html',
  styleUrls: ['async.alert.component.scss']
})
export class AsyncAlertComponent implements OnInit {
  public result: any = false;

  constructor(
    public dialogRef: MatDialogRef<AsyncAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }


  onYesClick(): void {
    this.result = true;
    this.dialogRef.close(this.result);
  }

  ngOnInit() { }
}
