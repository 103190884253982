import { Injectable, NgZone } from '@angular/core';
import { IJoinRequestParameters } from '../models/join.model';

export const JOIN_REQUEST_PARAMETERS_ID = "JoinRequestParameters";



@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  constructor(
  ) {
  
  }

  setJoinRequestParameters(joinRequestParameters: IJoinRequestParameters) {
    localStorage.setItem(JOIN_REQUEST_PARAMETERS_ID, JSON.stringify(joinRequestParameters));
  }

  getJoinRequestParameters() {
    const parsed = localStorage.getItem(JOIN_REQUEST_PARAMETERS_ID);
    if (parsed) {
      const parameters = JSON.parse(parsed as string) as IJoinRequestParameters;
      return parameters;
    } else return null;
  }

  removeJoinRequestParameters() {
    localStorage.removeItem(JOIN_REQUEST_PARAMETERS_ID);
  }

}
