import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, share, shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import { faListCheck, faHeart, faFaceSmile, faDollarSign, faMicrophone, faCircleUser, faBell, faUser, faQuestionCircle, faList, faRectangleList, faSignOut, faArrowLeft, faArrowRight, faCogs, faClock } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { CommonService } from '../common.service';
import { AsyncService } from 'src/app/core/async/async.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  faListCheck = faListCheck;
  faFaceSmile = faFaceSmile;
  faDollarSign = faDollarSign;
  faMicrophone = faMicrophone;
  faCircleUser = faCircleUser;
  faHeart = faHeart;
  faBell = faBell;
  faUser = faUser;
  faList = faList;
  faRectangleList = faRectangleList;
  faQuestionCircle = faQuestionCircle;
  faSignOut = faSignOut;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faCogs = faCogs;
  faClock = faClock;
  isAuthenticated: boolean = false;
  userData$: any;
  clientName: any = environment.clientName;
  currentComponent: any = {};
  logoUrl: string = "assets/logo_0.png";
  applicationVersion: string = environment.version;
  groupsPanelOpenState: boolean = false;
  isHandset$: Observable<boolean>;
  count: number = 0;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private service: CommonService,
    private async: AsyncService
  ) {
    this.router.navigate(["/home"]);
    this.isHandset$ = this.breakpointObserver?.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
   }

  ngOnInit() {
    
  }

  initData(){
  }

  enableBackdoor(){
    this.count++
    if(this.count > 6){
      this.logoUrl = "assets/logo_dark.png";
      this.service.isBackdoorEnabled = true;
      this.async.openAlert("Bravo!", "Backdoor aperta con successo!! Puoi usare vipvoice illimitatamente e con settaggi addizionali!", ()=>{});
    }
  }

  logout() {
  }

  goTo(component: any) {
    this.router.navigateByUrl(component.url);
    this.currentComponent = component;
  }

  goToSettings(){
    this.router.navigate(["/settings"]);
  }

  showNotifications() { }

  onAlphaTagClick() {
    const url = "https://sienna.acustica-audio.com/sienna-sphere-app/";
    window.open(url);
  }

  onFeedbackTagClick() {
    // const iframe = document.getElementById("launcher-frame") as any;
    // const button = iframe.contentWindow.document.getElementsByClassName("launcher-button")[0] as HTMLElement;
    // button.click();
  }

  hasBackNavigation(){
    return true;
  }

  hasForwardNavigation(){
    return true;
  }

  goBack(){
    this.location.back();
  }

  goForward(){
    this.location.forward();
  }

}
