import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  isBackdoorEnabled: boolean = false;
  maxRecorderTime: number = 60;

  constructor(
  ) {
    
  }

  getMaxRecorderTime(){
    return this.isBackdoorEnabled ? 3600 * 24 : this.maxRecorderTime;
  }

}