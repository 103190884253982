import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmptyErrorStateMatcher } from '../../matchers/form.matcher';

export interface EditorDialogData {
  title: string;
  message: string;
  field: any;
  fieldName: string;
}

@Component({
  selector: 'app-async-editor',
  templateUrl: 'async.editor.component.html',
  styleUrls: ['async.editor.component.scss']
})
export class AsyncEditorComponent implements OnInit {
  result: any = false;
  memento!: string;
  formControl = new FormControl('', [Validators.required]);
  matcher = new EmptyErrorStateMatcher();

  constructor(
    public dialogRef: MatDialogRef<AsyncEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditorDialogData,
  ) { 
    this.memento = new String(data.field).toString();

  }

  confirm(): void {
    this.dialogRef.close(this.data.field);
  }
  
  cancel(): void {
    this.dialogRef.close(this.memento);
  }

  ngOnInit() { }
}
