import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedComponent } from './components/authorized/authorized.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { HomeComponent } from './components/home/home.component';
import { LandingComponent } from './components/landing/landing.component';
import { BootstrapComponent } from './components/bootstrap/bootstrap.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthGuard } from './core/auth/auth.guard';
import { TermsComponent } from './components/terms/terms.component';

const appRoutes: Routes = [
  {
    path: '', component: UnauthorizedComponent, children:
      [
        { path: 'home', component: HomeComponent },
        { path: 'landing', component: LandingComponent },
        { path: 'terms', component: TermsComponent },
      ]
  },

  // otherwise redirect to home
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
 